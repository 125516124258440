@import "variables";
@import "_functions";


@font-face {
  font-family: $body-font-family;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/OpenSans-VariableFont_wdth,wght.ttf");
}


@import 'common/animations';

@import 'components/alert';
@import 'components/buttons';
@import 'components/forms';
@import 'components/input-range';

@import "footer/site-footer";

@import "pages/not-found";
@import "pages/suspend";

@import "shop/products-list";

@import "blog/comment";

@import "custom";
@import "global";
@import "builder_grid";

@import "components/smart-search/snart-search";