.zg-grid-col {
  width: 100%;
  //TODO!!! when we will open this
  //padding: 5px 10px;

  // TODO!!! please change this
  //next/components/builder/Column ->
  //<div className={`${columnClassname} ${!inView ? "dataAosRef" : ""}`} style={{ padding }}>
    //<div ref={columnRef} {...dataAosPropShow} style={otherClass}>
    //<VideoBackground src={videoSource}/>
    //<ChildComponentShow/>
    //</div>
  //</div>

  //TODO!!!  into this
  //<div
    //ref={columnRef}
    //{...dataAosPropShow}
    //  className={`${columnClassname} ${!inView ? "dataAosRef" : ""}`}
    //  style={{...columnStyles, position: 'relative'}}
  //>
    //<VideoBackground src={videoSource}/>
    //<ChildComponentShow/>
  //  </div>
}

.zg-col-lg {
  &-1 {
    max-width: 100%;
  }

  &-1-2 {
    max-width: 50%;
  }

  &-1-3 {
    max-width: calc(100% / 3);
  }

  &-1-4 {
    max-width: 25%;
  }

  &-1-5 {
    max-width: 20%;
  }

  &-1-6 {
    max-width: calc(100% / 6);
  }

  &-2-3 {
    max-width: calc((100% / 3) * 2);
  }
}

@media (max-width: 1400px) {
  .zg-container {
    max-width: 1140px;
  }
}

@media (max-width: 1200px) {
  .zg-container {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .zg-container {
    max-width: 720px;
  }
}

@media (max-width: 768px) {
  .zg-col-lg {
    &-1,
    &-1-2,
    &-1-3,
    &-1-4,
    &-1-5,
    &-1-6,
    &-2-3 {
      max-width: 100%;
    }
  }
}


@media (max-width: 768px) and (min-width: 576px) {
  .zg-container {
    max-width: 540px;
  }
}

@media only screen and (max-width: 500px) {
  .dataAosRef[data-aos=fade-left] {
    transform: translate3d(16px,0,0) !important
  }
}