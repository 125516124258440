@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.toast {
  border: 2px solid transparent;
  background-color: #fafafa;
  border-radius: 4px;
  max-width: 480px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  animation: toast-in-right .3s;
  color: #fff;
  padding: 8px;
}

.toast__close-btn {
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 3px;
  border-radius: 5px;

  &:hover {
    background-color: #ebebeb;
    color: #646363;
  }
}


.toast__text {
  color: black;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
}

.toasts-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 11111;
  max-width: 500px;
  width: 100%;

  @media screen and (max-width: 600px) {
    left: auto;
    right: 0;
    top: 1em;
    max-width: 350px;
    width: 100%;
  }
}