@import '../../../scss/variables';

.popup_wrapper {
  width: 100%;
  height: 100vh;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000000;

  .popup {
    width: 100%;
    max-width: 500px;
    min-height: 200px;
    background: #FFF;
    border-radius: 10px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    box-shadow: 0 0 10px #AAA;

    .popup_title {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 4px;
      color: #000;
      border-bottom: 1px solid #DDD;
    }

    .popup_buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      button {
        padding: 10px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        width: 100%;
        border-radius: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.accept {
          background: $btn-primary-bg-color;
          color: #fff;

          &:hover {
            background-color: $btn-primary-hover-bg-color;
            transition: all 0.1s ease-in-out;
            color: #fff;
          }
        }

        &.decline{
          border: 1px solid $btn-primary-bg-color !important;
          background: #FFF;
          color: #000;

          &:hover {
            border: 1px solid $btn-primary-hover-bg-color !important;
            transition: all 0.1s ease-in-out;
            color: #000;
          }
        }
      }
    }
  }
}

